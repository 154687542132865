import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  debug: process.env.NODE_ENV !== 'production',
  lng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
  resources: {
    en: { translations: require('../../static/locales/en/translations.json') },
    es: { translations: require('../../static/locales/es/translations.json') },
  },
});

export default i18n;
